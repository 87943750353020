////////// BACKGROUND COLOR //////////
.bg-primaryColor {
  background-color: rgba(252, 252, 252, 1);
}
.bg-secondaryColor {
  background-color: rgba(252, 252, 252, 1);
}
.bg-thirdColor {
  background-color: rgba(252, 252, 252, 1);
}
.bg-fourthColor {
  background-color: rgba(93, 44, 124, 1);
}
.bg-fifthColor {
  background-color: rgba(252, 252, 252, 1);
}
.bg-sixthColor {
  background-color: rgba(93, 44, 124, 1);
}
.bg-seventhColor {
  background-color: rgba(252, 252, 252, 1);
}
